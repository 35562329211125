<template>
  <div v-if="isDisplay" :class="classSelector">
    <slot></slot>
  </div>
</template>

<script setup>
import { computed, onMounted } from 'vue'

const props = defineProps({
  start: {
    type: String,
    required: true
  },
  end: {
    type: String,
    required: true
  },
  classSelector: {
    type: String,
    default: null
  }
})

const emit = defineEmits(['isDisplay'])

const isDisplay = computed(() => {
  const jstNow = new Date(Date.now() + ((new Date().getTimezoneOffset() + (9 * 60)) * 60 * 1000))
  return (new Date(props.start).getTime() < jstNow.getTime() && jstNow.getTime() < new Date(props.end).getTime())
})

onMounted(() => {
  emit('isDisplay', isDisplay.value)
})
</script>

<style scoped>
</style>
